import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const static_name_before = "";
const static_name_after = " - RVE Intranet";

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {title: static_name_before+"Login"+static_name_after},
    component: () =>
      import(/* webpackChunkName: "login" */ "@v/Login.vue"),
  },
  {
    path: "/",
    component: () => import("@l/Private.vue"),
    children: [
      {
        path: "/",
        name: "home",
        meta: {title: static_name_before+"Übersicht"+static_name_after},
        component: () => import("@v/Home.vue"),
      },
      {
        path: "beschwerden",
        name: "beschwerden",
        meta: {title: static_name_before+"Beschwerden"+static_name_after},
        component: () =>
          import(/* webpackChunkName: "beschwerden" */ "@v/Beschwerden.vue"),
      },
      {
        path: "beschwerden/neu",
        name: "beschwerdeNeu",
        meta: {title: static_name_before+"Neue Beschwerde"+static_name_after},
        component: () =>
          import(
            /* webpackChunkName: "beschwerdenNeu" */ "@v/BeschwerdeDetail.vue"
          ),
          props: {
            isNeueBeschwerde: true,
            beschwerdeId: null
          }
      },
      {
        path: "beschwerden/:beschwerdeId",
        name: "beschwerdeDetail",
        meta: {title: static_name_before+"Beschwerden-Detailansicht"+static_name_after},
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "beschwerden" */ "@v/BeschwerdeDetail.vue"
          ),
      },
      {
        path: "beschwerden/:beschwerdeId/print",
        name: "beschwerdeDetailPrint",
        meta: {title: static_name_before+"Beschwerden-Druckansicht"+static_name_after},
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "beschwerden" */ "@v/BeschwerdeDetailPrint.vue"
          ),
      },
      {
        path: "beschwerden/:beschwerdeId/printall",
        name: "beschwerdeDetailPrintComplete",
        meta: {title: static_name_before+"Beschwerden-Druckansicht"+static_name_after},
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "beschwerden" */ "@v/BeschwerdeDetailPrintComplete.vue"
          ),
      },
      {
        path: "tickets",
        name: "tickets",
        meta: {title: static_name_before+"Tickets"+static_name_after},
        component: () =>
          import(/* webpackChunkName: "tickets" */ "@v/Tickets.vue"),
      },
      {
        path: "profil",
        name: "profil",
        meta: {title: static_name_before+"Profil"+static_name_after},
        component: () =>
          import(/* webpackChunkName: "profil" */ "@v/Profil.vue"),
      },
      {
        path: "tagesdienste",
        name: "tagesdienste",
        meta: {title: static_name_before+"Tagesdienste"+static_name_after},
        component: () =>
          import(/* webpackChunkName: "profil" */ "@v/Tagesdienste.vue"),
      },
      {
        path: "tagesdienste/news",
        name: "news",
        meta: {title: static_name_before+"Tagesdienste News"+static_name_after},
        component: () =>
          import(/* webpackChunkName: "profil" */ "@v/News.vue"),
      },
      {
        path: "tagesdienste/ansicht",
        name: "tagesdienstansicht",
        meta: {title: static_name_before+"Tagesdienste ansehen"+static_name_after},
        component: () =>
          import(/* webpackChunkName: "profil" */ "@v/TagesdiensteAnsicht.vue"),
      },
      {
        path: "diensterlaeuterungen",
        name: "diensterlaeuterungen",
        meta: {title: static_name_before+"Diensterläuterungen"+static_name_after},
        component: () =>
          import(/* webpackChunkName: "profil" */ "@v/Diensterlaeuterungen.vue"),
      },
      {
        path: "diensterlaeuterungen/ansicht",
        name: "diensterlaeuterungenansicht",
        meta: {title: static_name_before+"diensterlaeuterungen ansehen"+static_name_after},
        component: () =>
          import(/* webpackChunkName: "profil" */ "@v/DiensterlaeuterungenAnsicht.vue"),
      },
      {
        path: "diensterlaeuterungen/ansicht2",
        name: "diensterlaeuterungenansicht2",
        meta: {title: static_name_before+"diensterlaeuterungen ansehen"+static_name_after},
        component: () =>
          import(/* webpackChunkName: "profil" */ "@v/DiensterlaeuterungenAnsichtNeu.vue"),
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  next();
});

export default router;
