import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { setupPlugins, setupComponents } from "@p/setup";
import filters from "./filters";
import { LOAD_USER } from "@st/session/actions.type"

import 'vue-select/dist/vue-select.css';

setupPlugins(Vue);
setupComponents(Vue);

Vue.filter("normalDate", filters.normalDate);

Vue.config.productionTip = false;

const initApp = () => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}

store.dispatch(`session/${LOAD_USER}`).then(async () => {
  initApp()
}).catch(() => {
  initApp()
  let pending = router.history.pending
  router.replace({ name: 'login', query: { redir: pending?.query?.redir || (pending?.name !== "session-expired" ? router.history.pending?.path : null) } })
})
