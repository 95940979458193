export const LOAD_MITARBEITER = "loadMitarbeiter";
export const LOAD_BESCHWERDEFORM = "loadBeschwerdeform";
export const LOAD_BESCHWERDEKATEGORIE = "loadBeschwerdekategorie";
export const LOAD_STANDORTE = "loadStandorte";
export const LOAD_ABTEILUNGEN = "loadAbteilungen";
export const LOAD_LINIEN = "loadLinien";
export const LOAD_ANREDEN = "loadAnrede";
export const LOAD_HALTESTELLEN = "loadHaltestellen";
export const LOAD_TICKETARTEN = "loadTicketarten";
export const LOAD_FAHRPLANPERIODEN = "loadFahrplanperioden";
